import { getRequest, postRequest, pictureRequest,urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 能源站点管理
// const urlTitle="/power-system"
export const energyStationApi = {
  // 获取企业
  queryEnterprise: () => {
    return getRequest(`${urlTitle}/asset/info/getConpanyIdAndName`);
  },
  // 获取省市区
  queryRegion: () => {
    return getRequest(`${urlTitle}/station/info/region`);
  },
  // 获取字典数据
  queryDictionary: (params) => {
    return getRequest(
      `${urlTitle}/station/info/getDictNameAndDictValue`,
      params
    );
  },
  // 能源站点列表
  queryStationDetails: (companyId) => {
    return getRequest(`/station/info/getStationByCompanyId/${companyId}`);
  },
  // 能源站点列表
  queryEnergyStation: (params) => {
    return getRequest(`${urlTitle}/station/info/index`, params);
  },
    // 
    queryStation: (companyId) => {
      return getRequest(`${urlTitle}//station/info/getStationByCompanyId`,companyId);
    },
  // 能源站点详情
  queryEnergyDetails: (id) => {
    return getRequest(`${urlTitle}/operation/inspection/form/get/${id}`);
  },
    // 获取能源站点图片
    queryPictureUrl: (params) => {
      return getRequest(`${urlTitle}/station/info/getUrl`,params);
    },
  // 创建能源站点
  createEnergyStation: (params) => {
    return pictureRequest(`${urlTitle}/station/info/save`, params, {
      "Content-Type": "multipart/form-data",
    });
  },
  // 修改能源站点
  updateEnergyStation: (params) => {
    return pictureRequest(`${urlTitle}/station/info/update`, params,{
      "Content-Type": "multipart/form-data",
    });
  },
  // 删除能源站点
  deleteEnergyStation: (id) => {
    return postRequest(`${urlTitle}/station/info/remove/${id}`);
  },
  // 获取所有运维单位列表
  getOpeComList: () => {
    return getRequest(`${urlTitle}/companyInfo/getOpeComList`);
  },
  //获取所有用户列表
  getUserList: ()=>{
    return getRequest(`${urlTitle}/user/getUserList`)
  },
  //获取所有电价
  selectStationElePriceList: ()=>{
    return getRequest(`${urlTitle}/station/info/selectStationElePriceList`)
  },
  //获取站点编号下拉
  getStationNumber: ()=>{
    return getRequest(`${urlTitle}/station/info/getSyncStationNumber`)
  }
};
